<template>
  <div>
    <b-container class="mt-5 mb-5">
      <h1 class="fs-4 font-weight-bold mb-4">Nieuw evenement</h1>
      <Stepper :step="step" :steps="steps" />

      <template v-if="step == 1">
        <ValidationObserver ref="form1" slim>
          <EventDetails :form="form" />
          <EventDates :form="form" />
          <EventLocation :form="form" />
          <EventSaleDate :form="form" />
          <EventMarketing :form="form" />
        </ValidationObserver>
      </template>

      <template v-if="step == 2">
        <ValidationObserver ref="form2" slim>
          <b-card v-for="(ticket, key) in form.tickets" :key="key" class="mb-3">
            <h4 class="fs-5 font-weight-bold mb-3">Ticket {{ key + 1 }}</h4>
            <div class="remove-slot">
              <b-button @click="removeTicket(ticket)" variant="outline-primary" class="float-right">
                <font-awesome-icon icon="fa-solid fa-remove ml-2" />
              </b-button>
            </div>
            <b-tabs content-class="p-3 tab-body">
              <b-tab title="Algemeen" active>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="mb-3">
                    <label class="required">Type ticket</label>
                    <b-form-select v-model="ticket.type" :options="ticketTypeOptions" />
                    <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>

                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="mb-3">
                    <label class="required">Naam ticket</label>
                    <b-form-input v-model="ticket.name" />
                    <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>

                <div class="mb-3">
                    <label>Beschrijving</label>
                    <b-form-textarea v-model="ticket.description" />
                </div>

                <div class="mb-3">
                  <label>Prijs</label>
                  <b-form-input v-money="money" v-model="ticket.price" min="0" placeholder="0.00" />
                </div>

                <div class="mb-3">
                  <label>Aantal beschikbare tickets</label>
                  <b-form-input v-model="ticket.amount_available" v-mask="'#########'" placeholder="Onbeperkt" min="0" />
                </div>

                <ValidationProvider rules="max:1000" v-slot="{ errors }">
                    <div class="mb-3">
                        <label>Extra Informatie</label>
                        <VueTrix v-model="ticket.extra_information" />
                        <p class="text-muted mb-1">Bijvoorbeeld: Informatie over toegangsbeleid of overige toevoegingen.</p>
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>
              </b-tab>

              <b-tab title="Afbeelding">
                <div class="mb-3">
                  <label>Upload een afbeelding voor op je ticket</label>
                  <p class="text-muted">Optimaal formaat: 2200x2500px (max. 5MB)</p>
                  <DropFile v-model="ticket.header_image" accept="image/*" />
                </div>
              </b-tab>
              <b-tab title="Verkoopdatum">
                <div class="mb-3">
                  <label>Gelijk aan evenement</label>
                  <b-form-select v-model="ticket.is_custom_sell_date" :options="customSaleDateOptions" />
                </div>
                <template v-if="ticket.is_custom_sell_date">
                  <b-row>
                    <b-col lg="6" class="mb-3">
                      <label class="required">Startdatum</label>
                      <b-row>
                        <b-col cols="8">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="mb-3">
                              <p class="text-muted mb-0">Datum</p>
                              <b-form-input v-model="ticket.start_sale_at_date" type="date" max="9999-12-31" />
                              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                            </div>
                          </ValidationProvider>
                        </b-col>
                        <b-col cols="4">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="mb-3">
                              <p class="text-muted mb-0">Tijd</p>
                              <b-form-input v-model="ticket.start_sale_at_time" type="time" />
                              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                            </div>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col lg="6" class="mb-3">
                      <label class="required">Einddatum</label>
                      <b-row>
                        <b-col cols="8">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="mb-3">
                              <p class="text-muted mb-0">Datum</p>
                              <b-form-input v-model="ticket.end_sale_at_date" type="date" max="9999-12-31" />
                              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                            </div>
                          </ValidationProvider>
                        </b-col>
                        <b-col cols="4">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="mb-3">
                              <p class="text-muted mb-0">Tijd</p>
                              <b-form-input v-model="ticket.end_sale_at_time" type="time" />
                              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                            </div>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </template>
              </b-tab>
              <b-tab title="Geldigheidsdatum">
                <template v-if="form.type == 'multiple'">
                  <div class="mb-3">
                    <label>Meerdaagse ticket</label>
                    <p class="text-muted mb-0">Deze ticket mag elke dag gebruikt worden.</p>
                    <b-form-select v-model="ticket.is_multiday_ticket" :options="yesNoOptions" />
                  </div>
                </template>
                <div class="mb-3">
                  <label>Gelijk aan evenement</label>
                  <b-form-select v-model="ticket.is_custom_valid_date" :options="customValidDateOptions" />
                </div>
                <template v-if="ticket.is_custom_valid_date">
                  <b-row>
                    <b-col lg="6" class="mb-3">
                      <label class="required">Startdatum</label>
                      <b-row>
                        <b-col cols="8">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="mb-3">
                              <p class="text-muted mb-0">Datum</p>
                              <b-form-input v-model="ticket.start_valid_at_date" type="date" max="9999-12-31" />
                              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                            </div>
                          </ValidationProvider>
                        </b-col>
                        <b-col cols="4">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="mb-3">
                              <p class="text-muted mb-0">Tijd</p>
                              <b-form-input v-model="ticket.start_valid_at_time" type="time" />
                              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                            </div>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col lg="6" class="mb-3">
                      <label class="required">Einddatum</label>
                      <b-row>
                        <b-col cols="8">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="mb-3">
                              <p class="text-muted mb-0">Datum</p>
                              <b-form-input v-model="ticket.end_valid_at_date" type="date" max="9999-12-31" />
                              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                            </div>
                          </ValidationProvider>
                        </b-col>
                        <b-col cols="4">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="mb-3">
                              <p class="text-muted mb-0">Tijd</p>
                              <b-form-input v-model="ticket.end_valid_at_time" type="time" />
                              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                            </div>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </template>
              </b-tab>
            </b-tabs>
          </b-card>

          <div class="text-center">
            <b-button variant="primary" @click="addTicket()">
              Ticket toevoegen
              <font-awesome-icon icon="fa-solid fa-plus ml-2" />
            </b-button>
          </div>
        </ValidationObserver>
      </template>

      <template v-if="step == 3">
        <ValidationObserver ref="form3" slim>
          <EventPersonal :form="form" />
        </ValidationObserver>
      </template>

      <template v-if="step == 4">
        <ValidationObserver ref="form4" slim>
          <EventOptions :form="form" />
        </ValidationObserver>
      </template>

      <div class="clearfix">
        <b-button v-if="step > 1" @click="prevStep" variant="outline-primary" class="float-left">
          <font-awesome-icon icon="fa-solid fa-chevron-left" />
          Vorige
        </b-button>

        <b-button v-if="step < 4" @click="nextStep" variant="primary" class="float-right">
          Volgende
          <font-awesome-icon icon="fa-solid fa-chevron-right" />
        </b-button>

        <b-button v-if="step == 4" @click="submitStep" variant="primary" class="float-right">
          Opslaan
          <font-awesome-icon icon="fa-solid fa-chevron-right" />
        </b-button>
      </div>

    </b-container>
  </div>
</template>

<script>
  import { VMoney } from 'v-money'
  import VueTrix from "vue-trix";
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import Stepper from '@/components/Stepper'
  import DropFile from "@/components/DropFile.vue";

  import EventDetails from '@/components/event/EventDetails.vue'
  import EventLocation from '@/components/event/EventLocation.vue'
  import EventSaleDate from '@/components/event/EventSaleDate.vue'
  import EventDates from '@/components/event/EventDates.vue'
  import EventOptions from '@/components/event/EventOptions.vue'
  import EventPersonal from '@/components/event/EventPersonal.vue'
  import EventMarketing from '@/components/event/EventMarketing.vue'

  export default {
      metaInfo: {
          title: 'Nieuw evenement',
      },
      components: {
        VueTrix,
        ValidationObserver,
        ValidationProvider,
        Stepper,
        DropFile,
        EventDetails,
        EventLocation,
        EventSaleDate,
        EventDates,
        EventOptions,
        EventPersonal,
        EventMarketing,
      },
      directives: {
        money: VMoney
      },
      data() {
          return {
            money: {
              decimal: '.',
              thousands: '',
              prefix: '',
              suffix: '',
              precision: 2,
            },
            step: 1,
            steps: [
              { icon: 'fa-calendar', title: 'Evenement' },
              { icon: 'fa-ticket', title: 'Tickets' },
              { icon: 'fa-user', title: 'Persoonsgegevens' },
              { icon: 'fa-cog', title: 'Extra opties' },
            ],
            form: {
              name: null,
              description: null,
              location_id: null,
              type: 'single',
              start_sale_at: null,
              slots: [],
              tickets: [],
              extra_details: [],
              extra_details_required: [],
              is_personal_ticket: false,
              personal_details_required: [],
              minimal_age: null,
              is_customer_transaction_fee: false,
              is_customer_ticket_fee: false,
              customer_ticket_fee: null,
              is_invoicing: false,
              invoicing_vat_rate: 21,
              is_accept_donations: false,
              donation_description: null,
              primary_category: null,
              secondary_category: null,
              tags: [],
              is_highlighted: false,
            },
            ticketTypeOptions: [
              { text: 'Selecteer een optie', value:null },
              { text: 'Toegangsticket', value:'ticket' },
              { text: 'Voucher', value:'voucher' },
            ],
            customSaleDateOptions: [
              { text: 'Ja', value: false },
              { text: 'Nee', value: true }
            ],
            customValidDateOptions: [
              { text: 'Ja', value: false },
              { text: 'Nee', value: true }
            ],
            yesNoOptions: [
              { text: 'Ja', value: true },
              { text: 'Nee', value: false }, 
            ]
          }
      },
      computed: {},
      methods: {
        removeTicket: function(ticket) {
          var index = this.form.tickets.indexOf(ticket);
          if (index !== -1) {
            this.form.tickets.splice(index, 1);
          }
        },
        addTicket: function() {
          this.form.tickets.push({
            type: null,
            is_custom_sell_date: false,
            is_custom_valid_date: false,
            is_multiday_ticket: false,
          });
        },
        prevStep: function() {
          this.step--;

          window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
          
        },
        async nextStep() {

          let check = await this.$refs['form' + this.step].validate();

          if (!check) {
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
            
            return;
          }

          this.step++;

          window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
        },
        submitStep: function() {
          this.$axios.post("https://api.tixgo.nl/events", this.form)
              .then( response => {
                response;
              this.$router.push('/manage/events');
          });
        },
      },
      created() {}
  }
</script>

<style>
  .type-toggle label{
    margin-left:10px;
    vertical-align: middle;
  }
  .type-toggle .custom-radio{
    border:1px solid #eee;
    border-radius:0.5rem;
    margin-bottom:15px;
    padding:15px;
  }
  .remove-slot{
    position:absolute;
    right:15px;
    top:15px;
  }
  .tab-body{
    border:1px solid #eee;
  }
</style>