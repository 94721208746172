<template>
    <div>
        <b-row class="mb-3 stepper shadow">
            <b-col :class="step == (1 + key) ? 'active' : (step > 1 + key ? 'done' : '')" v-for="(item, key) in steps" :key="key" class="item font-weight-bold">
                <template v-if="(step > 1 + key ? 'done' : '')">
                    <font-awesome-icon class="icon" icon="fa-solid fa-check" />
                </template>
                <template v-else>
                    <font-awesome-icon class="icon" :icon="`fa-solid ` + item.icon" />
                </template>
                {{ item.title }}
                <template v-if="1 + key < steps.length">
                    <svg class="divider" viewBox="0 0 12 82" fill="none" preserveAspectRatio="none">
                        <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="#D1D5DB" vector-effect="non-scaling-stroke"></path>
                    </svg>
                </template>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
      props: {
        steps: Array,
        step: Number,
      },
      methods: {}
    }
</script>

<style>
.stepper{
    background-color:#ffffff;
    border-radius:15px;
    margin:0;
    overflow:hidden;
}
.stepper .item{
    position:relative;
    padding:15px 25px;
    border-bottom:1px solid #D1D5DB;
}
.stepper .item.active .icon{
    color:var(--primary-color);
    border-color:var(--primary-color);
}
.stepper .item.active:after{
    content:"";
    height:5px;
    width:100%;
    position:absolute;
    left:0;
    right:0;
    bottom:0;
    background-color:var(--primary-color);
}
.stepper .item.done:after{
    content:"";
    height:5px;
    width:100%;
    position:absolute;
    left:0;
    right:0;
    bottom:0;
    background-color:#eeeeee;
}
.stepper .icon{
    border-radius:100%;
    border:1px solid #D1D5DB;
    padding:10px;
    vertical-align:middle;
    margin-right:8px;
}
.stepper .divider{
    position:absolute;
    top:0;
    right:-10px;
    height:100%;
    display:none;
}
@media screen and (min-width:768px) {
    .stepper .divider{
        display:block;
    }
    .stepper .item{
        border-bottom:0;
    }
}
@media screen and (max-width:767px) {
    .stepper .stepper > * {
        flex: 0 0 auto;
        width: 100%;
    }
}
</style>